<template>
  <div class="simpleControl-page">
    <div class="top-router">
      <RouterMenu />
    </div>
    <div class="main">

      <div class="left">

        <!-- 机器列表 -->
        <div class="son-header" style="display: flex; justify-content: space-between">
          <div>
            <Icon name="lineInfo" class="icon-class" />
            机器列表
          </div>
          <i class="el-icon-refresh" style="cursor: pointer" title="刷新机器列表" @click="refreshRobotList"></i>
        </div>
        <div class="robot-list">
          <ul>
            <li v-for="(robot, index) in getAllOnlineRobotList" :key="index" :class="{ 'not-online': !robot.isOnline, 'li-actived': robot.robotMac == currentLoginedRobot.robotMac }" v-loading.fullscreen.lock="isLoginLoading" element-loading-text="正在登录，请稍候~" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.5)">
              <el-tooltip class="item" effect="light" :content="robot.robotName" placement="right" :open-delay="1000">
                <span class="robotName">
                  <Icon name="greenMachine" />
                  {{ robot.robotName }}
                </span>
              </el-tooltip>
              <span v-if="!robot.isOnline" style="text-align:right"> 离线</span>
              <el-button class="kr-normal-button" v-else-if="robot.robotMac == currentLoginedRobot.robotMac" style="text-align:right" @click="logoutRobotBtn(robot)">退出</el-button>
              <el-button class="kr-normal-button" :disabled="!robot.isOnline" @click="loginRobotBtn(robot)" v-else-if="$hasElement('robot-btn-login')">登录</el-button>
            </li>
          </ul>
        </div>

        <!-- 机器人运动信息 -->
        <div class="son-header log-admin">
          <el-button class="kr-normal-button" @click="logOrAdmin = 0" :class="logOrAdmin == 0 ? 'green-button-actived' : ''">
            <Icon name="baseInfo" />
            操作日志
          </el-button>
          <el-button v-if="false" class="kr-normal-button" @click="logOrAdmin = 1" :class="logOrAdmin == 1 ? 'green-button-actived' : ''">
            <Icon name="defect" />
            管理模式
          </el-button>
        </div>
        <div class="log-admin-list">
          <div class="log-div" v-if="logOrAdmin == 0">
            <LogPanel ref="LogPanel" />
          </div>
          <div class="admin" v-if="logOrAdmin == 1">
            <div class="admin-item" v-for="(adminBtn, index) in adminList" :key="index">
              <div class="admin-title">{{ adminBtn.title }}</div>
              <el-button class="normal-small-button" :disabled="getIsDisabled" @click="adminSwitchBtn(adminBtn.cmd1)" v-if="$hasElement('robot-btn-query')">{{ adminBtn.label1 }}</el-button>
              <el-button class="normal-small-button" :disabled="getIsDisabled" @click="adminSwitchBtn(adminBtn.cmd2)" v-if="$hasElement('robot-btn-query')">{{ adminBtn.label2 }}</el-button>
            </div>
          </div>
        </div>
      </div>

      <!-- 监控视频 -->
      <div class="center">
        <centerVideo2 :cameraList="cameraList" v-if="currentLoginedRobot.robotType === 'ROBOT_TYPE_DISTRIBUTED'" />
        <centerVideo :modeType="0" videoStatus=""  :videoKey="0" :isStop="0" v-else />
      </div>

      <!-- 机器操作 -->
      <div class="right">

        <!-- 头部tab -->
        <div class="son-header baseInfo-ctrl">
          <el-button class="kr-normal-button" @click="goToControl()">基础信息</el-button>
          <!-- <el-button class="kr-normal-button green-button-actived">机器控制</el-button> -->
        </div>

        <div class="simple-ctrl">

          <div class="sub-title">机器控制</div>
          <div class="robot-control">
            <div class="robot-photo" style="position:relative;">
              <div class="cloud-speed" style="position:absolute;right:0;top:1vh;">
                <el-slider v-model="cloudSpeed" :min="100" :max="9000" vertical height="14vh" @change="robotSpeedChange"></el-slider>
              </div>
            </div>
            <div class="robot-button">
              <el-button :disabled="getIsDisabled" class="robot-backward" title="后退" @click="robotMove('backward')"></el-button>
              <el-button :disabled="getIsDisabled" class="robot-stop" title="停止" @click="robotStop"></el-button>
              <el-button :disabled="getIsDisabled" class="robot-forward" title="前进" @click="robotMove('forward')"></el-button>
            </div>
          </div>

          <div class="sub-title">云台控制</div>
          <div class="cloud-control">
            <cloudControl />
          </div>

          <div class="sub-title">充电方案</div>
          <div style="margin: 0.4vh">
            <el-select class="normal-select" v-model="chargeId" placeholder="请选择充电方案" v-if="$hasElement('charge-btn-getChargeInfos')" >
              <el-option v-for="item in ChargeOptions" :key="item.id" :label="item.describes" :value="item.id"></el-option>
            </el-select>
            <el-button :disabled="getIsDisabled" class="normal-small-button" style="margin: 0" title="充电" @click="goToCharge" v-if="$hasElement('robot-btn-toCharge')">充电</el-button>
            <el-button :disabled="getIsDisabled" class="normal-small-button" style="margin: 0.2vh 0.5vw" title="新增方案" @click="newChargeDialogVisible = true" v-if="$hasElement('charge-btn-addChargeInfo')">新增方案</el-button>
            <el-button :disabled="getIsDisabled" class="normal-small-button" style="margin: 0" title="删除" @click="deleteCharge" v-if="$hasElement('charge-btn-deleteChargeInfo')">删除</el-button>
          </div>

          <div class="sub-title">任务模板</div>
          <div style="margin: 0.4vh">
            <el-select v-model="planTemplate" placeholder="请选择自动运行模板" @change="selectTemplates" class="normal-select" clearable>
              <el-option v-for="(item, index) in taskTemplateList" :key="index" :label="item.templateName" :value="item.id"></el-option>
            </el-select>
          </div>

          <div class="sub-title">自动运行</div>
          <div>
            <el-form :model="autoRunForm" class="autorun-from" style="text-align: center" ref="autoRunFormRef">
              <el-form-item label="起始塔杆" :label-width="formLabelWidth" prop="obbeginnum">
                <el-input class="normal-input" v-model.trim.number="autoRunForm.obbeginnum" auto-complete="off"></el-input>
                <el-checkbox v-model="autoRunForm.obbeginflag">穿过此塔</el-checkbox>
              </el-form-item>
              <el-form-item label="终止塔杆" :label-width="formLabelWidth" prop="obendnum">
                <el-input class="normal-input" v-model.trim.number="autoRunForm.obendnum" auto-complete="off"></el-input>
                <el-checkbox v-model="autoRunForm.obendflag">过直线段</el-checkbox>
              </el-form-item>
              <el-form-item label="返回塔号" :label-width="formLabelWidth" prop="returnnum">
                <el-input class="normal-input" v-model.trim.number="autoRunForm.returnnum" auto-complete="off"></el-input>
                <el-checkbox v-model="autoRunForm.returnflag">穿过此塔</el-checkbox>
              </el-form-item>
              <el-form-item class="inspect-type">
                <el-radio-group v-model="autoRunForm.inspectMode">
                  <el-radio label="2">精细化巡检</el-radio>
                  <el-radio label="1">通道巡检</el-radio>
                  <el-radio label="0">无拍摄巡检</el-radio>
                  <el-radio label="3">红外巡检</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
            <div class="auto-btn" style="display: flex">
              <el-button :disabled="getIsDisabled" class="normal-small-button" style="margin: 0" @click="autoRunConfirmDirection(1)" v-if="isSameTowerNum" >前进</el-button>
              <el-button :disabled="getIsDisabled" class="normal-small-button" style="margin: 0 1vw" @click="autoRunConfirmDirection(2)" v-if="isSameTowerNum">后退</el-button>
              <el-button :disabled="getIsDisabled" class="normal-small-button" style="margin: 0" @click="isSameTowerNum = false" v-if="isSameTowerNum">返回</el-button>
              <el-button :disabled="getIsDisabled" class="normal-small-button" style="margin: 0" @click="autoRunConfirm" v-if="$hasElement('robot-btn-autorun') && !isSameTowerNum">开始</el-button>
              <el-button :disabled="getIsDisabled" class="normal-small-button" style="margin: 0 1vw" @click="clearOrContinue('continueautorun')" v-if="!isSameTowerNum">继续</el-button>
              <el-button :disabled="getIsDisabled" class="normal-small-button" style="margin: 0" @click="clearOrContinue('delautorundata')" v-if="!isSameTowerNum">清除</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- 新增充电方案 -->
    <el-dialog class="my-dialog" :close-on-click-modal="false" title="新增充电方案" :visible.sync="newChargeDialogVisible">
      <el-form :model="newChargeForm" ref="newChargeForm" :rules="newChargeFormRules">
        <el-form-item label="方案号" :label-width="chargeformLabelWidth" prop="chargeNumber">
          <el-input v-model.trim.number="newChargeForm.chargeNumber" auto-complete="off" placeholder="请输入方案号" @input="towerInputFormatter"></el-input>
        </el-form-item>
        <el-form-item label="线路" :label-width="chargeformLabelWidth" prop="lineId">
          <el-select v-model="newChargeForm.lineId" placeholder="请选择线路" @change="chooseLine">
            <el-option v-for="(line, index) in allLines" :key="index" :label="line.lineName" :value="line.lineId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机器" :label-width="chargeformLabelWidth" prop="robotId">
          <el-select v-model="newChargeForm.robotId" placeholder="请选择机器人">
            <el-option v-for="(robot, index) in robotByLine" :key="index" :label="robot.robotName" :value="robot.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="方向" :label-width="chargeformLabelWidth" prop="runDir">
          <el-select v-model="newChargeForm.runDir" placeholder="请选择">
            <!-- <el-option label="请选择方向" value=""></el-option> -->
            <el-option label="前进" value="1"></el-option>
            <el-option label="后退" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述" :label-width="chargeformLabelWidth" prop="describes">
          <el-input v-model="newChargeForm.describes" auto-complete="off" placeholder="请输入描述"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="cancelNewCharge">取 消</el-button>
        <el-button class="save-button" @click="saveNewCharge('newChargeForm')">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  loginRobot,
  logoutRobot,
  robotBrake,
  robotWalk,
  robotSwitch,
  getAllRobot,
  getOnlineRobot,
  getChargeInfos,
  getAllTemplate,
  robotClearOrContinueAutorun,
  robotAutorun,
  addCharge,
  deleteCharge,
  getRobotsByLineId,
  toCharge,
  rebootRoute
} from "@/http/professionalControlApi.js";
import { getCameraListByRobotIdApi } from "@/http/equipmentApi"
import { getLineNames } from "@/http/homeApi.js";
import RouterMenu from "@/components/RouterMenu.vue";
import LogPanel from "../../components/control/LogPanel.vue";
import Icon from "@/components/Icon.vue";
import cloudControl from "../../components/control/cloudControl.vue";
import centerVideo from "@/components/centerVideo.vue";
import { mapState, mapGetters, mapMutations} from "vuex";
import { loginH5sStream, keepAliveSession } from '../../http/equipmentApi'
import * as robotWebsocket from "@/utils/websocket/robotWebsocket.js";
import centerVideo2 from "../../components/centerVideo2.vue";
export default {
  name: "simpleControl",
  components: {
    LogPanel,
    RouterMenu,
    Icon,
    cloudControl,
    centerVideo,
    centerVideo2
  },
  data() {
    return {
      /*
       * @Date 2022/06/15 15:01:50
       * 操作日志、管理模式信息
       */
      logOrAdmin: 0,        // 显示日志还是管理模式
      adminList: [          // 管理模式按钮列表
        {
          title: "机器人",
          label1: "关闭程序",
          cmd1: "",
          label2: "重启网络",
          cmd2: "rebootRoute"
        },
        {
          title: "充电",
          label1: "开启",
          cmd1: "chargeopen",
          label2: "关闭",
          cmd2: "chargeclose"
        },
        {
          title: "机构系统",
          label1: "开启",
          cmd1: "mechpowon",
          label2: "关闭",
          cmd2: "mechpowoff"
        },
        {
          title: "板载",
          label1: "开启",
          cmd1: "carrierpowon",
          label2: "关闭",
          cmd2: "carrierpowoff"
        },
        {
          title: "摄像头",
          label1: "开启",
          cmd1: "camerapowon",
          label2: "关闭",
          cmd2: "camerapowoff"
        },
        {
          title: "风扇",
          label1: "开启",
          cmd1: "fanon",
          label2: "关闭",
          cmd2: "fanoff"
        }
      ],

      /*
       * @Date 2022/06/15 15:08:21
       * 机器控制信息
       */
      cloudSpeed: 300,      // 机器移动速度
      isLoginLoading: false,// 是否正在登录机器人 
      timeOutTimer: null,   // 超时定时器
      cameraList: [],   // 分布式机器人摄像头列表

      /*
       * @Date 2022/06/15 15:10:12
       * 充电方案信息
       */
      ChargeOptions: [],            // 可选充电方案
      chargeId: "",                 // 选择的充电方案
      newChargeDialogVisible: false,// 是否打开新增充电方案对话框
      chargeformLabelWidth: "5vw",
      newChargeForm: {              // 表单信息
        robotId: "",
        lineId: "",
        chargeNumber: "",
        runDir: "",
        describes: ""
      },
      newChargeFormRules: {
        lineId: [{ required: true, message: "请选择线路", trigger: "blur" }],
        robotId: [{ required: true, message: "请选择机器", trigger: "change" }],
        chargeNumber: [
          { required: true, message: "请输入塔号", trigger: "blur" },
          { type: "number", message: "塔号必须为数字", trigger: "blur" }
        ],
        runDir: [{ required: true, message: "请选择方向", trigger: "blur" }],
        describes: [{ required: true, message: "请描述", trigger: "blur" }]
      },
      allLines: [],                // 可选的线路信息
      robotByLine: [],             // 可选的机器人


      /*
       * @Date 2022/06/15 15:18:23
       * 任务模板信息
       */
      taskTemplateList: [], // 可选的任务模板
      planTemplate: "",     // 选择的任务模板


      /*
       * @Date 2022/06/15 15:19:31
       * 自动运行信息
       */
      formLabelWidth: "4vw",
      isSameTowerNum: false,
      autoRunForm: {          // 表单信息
        obbeginflag: "",      // 是否过起始塔
        obbeginnum: "",       // 起始塔号
        obendflag: "",        // 是否过结束塔
        obendnum: "",         // 结束塔号
        returnflag: "",       // 是否过返回塔
        returnnum: "",        // 返回塔号
        inspectMode: "2"
      },
      // autoRunFormRules: {
      //   obbeginnum: [{ required: true, message: "请输入起始塔", trigger: "blur" }],
      //   obendnum: [{ required: true, message: "请输入起始塔", trigger: "blur" }],
      //   returnnum: [{ required: true, message: "请输入起始塔", trigger: "blur" }]
      // },
    };
  },

  computed: {
    ...mapState(["userMessage", "currentLoginedRobot", "session", "timeout"]),
    ...mapGetters(["getAllOnlineRobotList", "getIsDisabled"])
  },

  created() {
    this.getAllRobotList();
    this.getOnlineRobotList();
    this.getAllTemplateList();
    getLineNames().then(res => {
      this.allLines = res.data;
    });
  },

  watch: {
    currentLoginedRobot: {
      handler(newVal, oldVal) {
        if (newVal.id) {
          getChargeInfos({ robotMac: newVal.id }).then(res => {
            this.ChargeOptions = res.data;
          });
        }

        setTimeout(() => {
          this.isLoginLoading = false;
          if (newVal.robotControlToken !== oldVal.robotControlToken && newVal.robotControlToken) {
            this.$message.success("登录成功")
          }
        }, 1000)
        clearTimeout(this.timeOutTimer);
        this.timeOutTimer = null;
        if(!this.session) {
          this.loginH5s();
        }
        if (newVal.robotType === 'ROBOT_TYPE_DISTRIBUTED') {
          let result = this.getAllOnlineRobotList.find(robotItem => {
            return robotItem.robotMac === newVal.robotMac;
          });
          if (result) {
            getCameraListByRobotIdApi({
              robotId: result.robotId
            }).then(res => {
              if (res.code === "000000") {
                this.cameraList = res.data;
              } else {
                this.$message.error(res.data);
              }
            }).catch(err => {
              console.log(err);
            })
          }
        }

      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    ...mapMutations(["updateSession", "updateTimeout", "updateCurrentLoginedRobot"]),

    /**
     * @Author KR0288
     * @Date 2022/06/15 14:41:54
     * @Description 获取该用户下的所有机器人,并保存在vuex中
     */
    getAllRobotList() {
      let params = {
        pageNumber: 1,
        pageSize: 1000
      };
      getAllRobot(params).then(res => {
        this.$store.commit("saveAllRobot", res.rows);
      }).catch(err => {
        this.$message.error("获取机器人列表失败");
      })
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 14:42:05
     * @Description 获取该用户下所有在线的机器人，并保存在vuex中
     */
    getOnlineRobotList() {
      getOnlineRobot({ 
        userName: this.userMessage.userName 
      }).then(res => {
        if (res.data) {
          let onlineRobot = Object.values(res.data);
          this.$store.commit("saveOnlineRobot", onlineRobot);
        }
      }).catch(err => {
        this.$message.error("获取在线机器人列表失败");
      })
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 15:24:34
     * @Description 刷新机器人列表
     */
    async refreshRobotList() {
      // let params = {
      //   pageNumber: 1,
      //   pageSize: 1000
      // };
      // let AllRobot = await getAllRobot(params);
      // this.$store.commit("saveAllRobot", AllRobot.rows);
      // let OnlineRobot = await getOnlineRobot({ 
      //   userName: this.userMessage.userName 
      // });
      // if (OnlineRobot.code=="000000") {
      //   this.$store.commit("saveOnlineRobot", Object.values(OnlineRobot.data));
      //   this.$message.success("刷新机器列表成功！");
      // } else {
      //   this.$store.commit("saveOnlineRobot", []);
      // }
      try {
        await this.getAllRobotList()
        await this.getOnlineRobotList();
        this.$message.success("刷新机器人列表成功")
      }catch(err) {
        this.$message.error("刷新失败")
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 14:42:12
     * @Description 登录机器人
     */
    loginRobotBtn(robot) {
      this.isLoginLoading = true;

      // 1、点击登录机器人按钮的时候，先切换订阅主题。
      robotWebsocket.changeSubscribeRobot(robot.robotMac);

      // 2、超过20秒就按超时处理，刷新页面
      this.timeOutTimer = setTimeout(() => {
        this.isLoginLoading = false;
        this.$message.error("登录超时");
        setTimeout(() => {
          location.reload();
        }, 1000)
      }, 20000)

      // 3、构造参数并登录机器人
      let params = { 
        userName: this.userMessage.userName, 
        isForce: false 
      };
      loginRobot(params, robot.robotMac).then(res => {
        if (res.code=="000000") {

          // 4、更新当前登录机器人信息
          let currentLoginedRobot = { 
            robotMac: robot.robotMac, 
            robotName: robot.robotName, 
            robotType: robot.robotType 
          };

          // 1、清空操作日志
          this.$refs.LogPanel.clearLog();
          this.$store.commit("updateCurrCtrlRobot", currentLoginedRobot);

          // 5、查询充电点信息
          getChargeInfos({ robotMac: robot.robotMac }).then(res => {
            this.ChargeOptions = res.data;
          });

        } else if (res.serviceStatusCode == "1000405") {
          this.isLoginLoading = false;
          this.$confirm(`${res.data.userName}已经登录该机器人，是否强制登录？`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
            this.isLoginLoading = true;
            let params = { 
              userName: this.userMessage.userName, 
              isForce: true 
            };
            loginRobot(params, robot.robotMac).then(res => {
              if (res.code=="000000") {
                let currentCtrlRobot = { 
                  robotMac: robot.robotMac, 
                  robotName: robot.robotName, 
                  robotType: robot.robotType 
                };
                this.$store.commit("updateCurrCtrlRobot", currentCtrlRobot);
              }
              
              this.timeOutTimer = setTimeout(() => {
                this.isLoginLoading = false;
                this.$message.error("登录超时");
                setTimeout(() => {
                  location.reload();
                }, 1000)
              }, 10000)
              
            });

          }).catch(err => {
            /*
            * @Date 2022/03/31 11:32:41
            * 取消登录的时候，将订阅主题切换到原来的主题
            */
            setTimeout(() => {
              robotWebsocket.changeSubscribeRobot(this.$store.state.currentCtrlRobot.robotMac);
            }, 500);
            this.$message("已取消登录");
            this.isLoginLoading = false;
          });
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/20 09:42:19
     * @Description 登录h5sPlayer
     */
    loginH5s() {
      loginH5sStream({
        user: "admin",
        password: "ab6f67cdc3b8a228e6ce227b39e25217"
      }).then(res => {
        console.log(res);
        if(res.bStatus) {
          this.updateSession(res.strSession);
          this.updateTimeout(res.nTimeout);
          setInterval(() => {
            this.keepAliveRobotSession();
          }, parseInt((this.timeout * 1000) - 1000));
        }
      })
    },

        /**
     * @description: 登出机器人
     * @date 2023-09-05 10:22
     */
    logoutRobotBtn(robot) {
      logoutRobot({
        robotMac: robot.robotMac
      }, this.currentLoginedRobot.robotControlToken).then(res => {
        if (res.code === "000000") {
          // 1、清除当前登录机器人信息
          this.updateCurrentLoginedRobot({        
            robotName: "",
            robotType: "ROBOT_TYPE_25", // 1:50kg 2:25kg
            robotMac: "",               // 机器人mac地址
            robotControlToken: "",      // 登录后获取到的操作机器人token
            count: 100,                 // 位置模式下的范围是100~6900
            velocity: 300,              // 机械臂运动范围是300~18000,机器人前进后退范围300~8000
            model: 1,                   // 模式，1运动模式，2位置模式
            electron: "",               // 当前机器人电量
            h5sToken: {                 // 播放视频的token
              live1: "", 
              live2: "", 
              live3: "", 
              live4: "",
              live5: "",
              live6: ""
            } 
          })
          
          // 2、清空日志
          this.$refs.LogPanel.clearLog();

          // 3、清空视频, 分布式机器人已在centerVideo2关闭了视频
          this.videoStatus = 0;
          this.$message.success("退出机器成功");
        } else {
          this.$message.error(res.mesg)
        }
      }).catch(err => {
        console.log(err);
      })
    },

    /**
     * @Author KR0288
     * @Date 2022/06/21 09:14:02
     * @Description 保活Session
     */
    keepAliveRobotSession() {
      keepAliveSession({
        session: this.session
      }).then(res => {
        console.log(res);
      })
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 16:59:30
     * @Description 管理模式的命令发送
     */
    adminSwitchBtn(cmd) {
      if (cmd == "rebootRoute") {
        rebootRoute().then(res => {
          if (res.code=="000000") {
            this.$message({
              message: res.mesg,
              type: "success"
            });
          } else {
            this.$message({
              message: res.data,
              type: "error"
            });
          }
        });
      } else if (cmd != "") {
        robotSwitch({ cmd: cmd }).then(res => {
          if (res.code=="000000") {
            this.$message({
              message: res.mesg,
              type: "success"
            });
          } else {
            this.$message({
              message: res.data,
              type: "error"
            });
          }
        });
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 17:01:50
     * @Description 点击基础信息跳转到主页
     */
    goToControl() {
      this.$router.push({ path: "home" });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 17:02:28
     * @Description 机器人速度改变
     */
    robotSpeedChange(speed) {
      this.$store.commit("updateRobotVelocity", speed);
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 17:03:21
     * @Description 机器人前进后退按钮
     */
    robotMove(cmd) {
      robotWalk({
        cmd: cmd,
        data: {
          count: this.currentLoginedRobot.count,
          model: this.currentLoginedRobot.model,
          velocity: this.currentLoginedRobot.velocity //速度模式下有效值300~18000
        }
      })
        .then(res => {
          //业务逻辑
          console.log(res);
        })
        .catch(error => {
          console.log(error);
        });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 17:03:32
     * @Description 机器人停止
     */
    robotStop() {
      robotBrake().then(res => {
          console.log("机器人停止", res);
      }).catch(error => {
          console.log(error);
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 17:04:46
     * @Description 充电
     */
    goToCharge() {
      if(!this.chargeId){
         this.$message({
            message: "请选择充电方案",
            type: "warning"
          });
      }
      let chargeId = this.chargeId;
      toCharge({ id: chargeId }).then(res => {
        if (res.code=="000000") {
          this.$message({
            message: res.mesg,
            type: "success"
          });
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 17:08:48
     * @Description 校正塔号格式
     */
    towerInputFormatter(val) {
      return val.replace(/[^\d]/g, "");
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 17:10:21
     * @Description 选择线路后通知用户是否有可选的机器人
     */
    chooseLine(lineId) {
      getRobotsByLineId({ lineId: lineId }).then(res => {
        if (res.code == '000000' && res.data) {
          this.robotByLine = res.data;
          this.newChargeForm.robotId = "";
        } else {
          this.robotByLine = [
            {
              name: "该线路无可选机器人",
              id: "-1"
            }
          ];
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 17:05:10
     * @Description 保存新增的充电方案
     */
    saveNewCharge(formName) {
      this.newChargeForm.robotMac = this.currentLoginedRobot.robotMac;
      this.$refs[formName].validate(valid => {
        if (valid) {
          addCharge(this.newChargeForm).then(res => {
            if (res.code=="000000") {
              this.newChargeDialogVisible = false;
              this.$message({
                message: res.mesg,
                type: "success"
              });
              if (this.currentLoginedRobot.robotMac != "") {
                getChargeInfos({ robotMac: this.currentLoginedRobot.robotMac }).then(res => {
                  this.ChargeOptions = res.data;
                });
              }
            } else {
              this.$message.error(res.data);
            }
          });
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 17:08:02
     * @Description 取消新增
     */
    cancelNewCharge() {
      this.newChargeDialogVisible = false;
      this.newChargeForm = {
        robotId: "",
        lineId: "",
        chargeNumber: "",
        runDir: "",
        describes: ""
      };
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 17:10:57
     * @Description 删除所选方案
     */
    deleteCharge() {
      let chargeId = this.chargeId;
      if(chargeId === "") {
        this.$message.error('请先选择充电方案')
        return;
      }
      deleteCharge({ id: chargeId }).then(res => {
        if (res.code=="000000"){
          this.$message({
            message: res.mesg,
            type: "success"
          });
          this.chargeId = "";
          if (this.currentLoginedRobot.robotMac != "") {
            getChargeInfos({ robotMac: this.currentLoginedRobot.robotMac }).then(res => {
              this.ChargeOptions = res.data;
            });
          }
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 17:14:23
     * @Description 获取可选的任务模板
     */
    getAllTemplateList() {
      getAllTemplate().then(res => {
        this.taskTemplateList = res.rows;
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 17:09:42
     * @Description 选择任务模板
     */
    selectTemplates(id) {
      if (id !== "") {
        this.taskTemplateList.forEach(item => {
          if (item.id === id) {
            console.log(item, item.returnTower)
            this.autoRunForm.obbeginnum = item.beginTower === null ? "" : item.beginTower.towerIndex;
            this.autoRunForm.obendnum = item.endTower === null ? "" : item.endTower.towerIndex;
            this.autoRunForm.returnnum = item.returnTower === null ? "" : item.returnTower.towerIndex;
            this.autoRunForm.obbeginflag =  item.beginTower.isThroughout === null ? false : item.beginTower.isThroughout;
            this.autoRunForm.obendflag = item.endTower.isThroughout === null ? false : item.endTower.isThroughout;
            this.autoRunForm.returnflag = item.returnTower === null ? false : item.returnTower.isThroughout;
            if(item.inspectMode=="PLAN_TYPE_NO_PHOTO_SHOOT"){
              this.autoRunForm.inspectMode = "0";
            }
              if(item.inspectMode=="PLAN_TYPE_CHANNEL"){
              this.autoRunForm.inspectMode = "1";
            }
              if(item.inspectMode=="PLAN_TYPE_FINE"){
              this.autoRunForm.inspectMode = "2";
            }
              if(item.inspectMode=="PLAN_TYPE_INFRARED"){
              this.autoRunForm.inspectMode = "3";
            }
          
          }
        });        
      } else {
        this.autoRunForm = {          // 表单信息
          obbeginflag: "",      // 是否过起始塔
          obbeginnum: "",       // 起始塔号
          obendflag: "",        // 是否过结束塔
          obendnum: "",         // 结束塔号
          returnflag: "",       // 是否过返回塔
          returnnum: "",        // 返回塔号
          inspectMode: "2"
        };
      }

    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 17:13:15
     * @Description 开始自动行走
     */
    autoRunConfirm() {
      if (this.autoRunForm.obbeginnum == "") {
        this.$message.warning("起始塔号不能为空");
        return;
      }
      if (this.autoRunForm.obendnum == "") {
        this.$message.warning("终止塔号不能为空");
        return;
      }
      if (this.autoRunForm.obbeginnum !== this.autoRunForm.obendnum) {
        let data = JSON.parse(JSON.stringify(this.autoRunForm));
        data.obbeginflag = this.autoRunForm.obbeginflag ? 1 : 0;
        data.obendflag = this.autoRunForm.obendflag ? 1 : 0;
        data.returnflag = this.autoRunForm.returnflag ? 1 : 0;
        robotAutorun(data).then(res => {
          if (res.code=="000000") {
            this.$message({
              message: res.mesg,
              type: "success"
            });
          } else {
            this.$message({
              message: res.data,
              type: "warning"
            });
          }
        });
      } else {
        this.isSameTowerNum = true;
      }
    },

    /**
     * @description: 当起始塔杆和结束塔杆箱同时，选择方向
     * @date 2023-09-06 13:57
     */
    autoRunConfirmDirection(direction) {
        let data = JSON.parse(JSON.stringify(this.autoRunForm));
        data.obbeginflag = this.autoRunForm.obbeginflag ? 1 : 0;
        data.obendflag = this.autoRunForm.obendflag ? 1 : 0;
        data.returnflag = this.autoRunForm.returnflag ? 1 : 0;
        data.dir = direction;
        robotAutorun(data).then(res => {
          if (res.code=="000000") {
            this.$message({
              message: res.mesg,
              type: "success"
            });
          } else {
            this.$message({
              message: res.data,
              type: "warning"
            });
          }
        });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 17:13:46
     * @Description 清除或继续自动运行
     */
    clearOrContinue(cmd) {
      robotClearOrContinueAutorun({ cmd: cmd }).then(res => {
        if (res.code=="000000") {
          this.$message({
            message: res.mesg,
            type: "success"
          });
        } else {
          this.$message({
            message: res.data,
            type: "warning"
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.simpleControl-page {
  height: calc(100% - 5vh);
  padding: 0 1vw;
  color: #fff;
  .top-router {
    padding: 0 1vw;
    position: relative;
  }
  .main {
    height: calc(100% - 5vh);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .left {
      width: 13.6vw;
      height: 88vh;
      .robot-list {
        margin-bottom: 2vh;
        .sub-title {
          padding: 1vh 0;
          font-size: 1.3vh;
          display: flex;
          justify-content: space-around;
        }
        ul {
          background-color: rgba(0, 0, 0, 0.5);
          height: 33vh;
          padding: 0;
          margin: 0;
          overflow-y: auto;
          li {
            padding: 0.5vh 0.5vh;
            list-style: none;
            font-size: 1.3vh;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .robotName {
              width: 80%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            // .robotName:hover::after {
            //   content: attr(data-title);
            //   display: inline-block;
            //   padding: 10px 14px;
            //   border: 1px solid #ddd;
            //   border-radius: 5px;
            //   color: #333;
            //   background-color: #EEE;
            //   position: fixed;
            //   width: 250px;
            //   line-height:18px;
            //   white-space: pre-wrap;
            // }

            .kr-normal-button {
              padding: 0.5vh;
              cursor: pointer;
            }
          }
          .not-online {
            color: #aaa;
          }
        }
      }
      .log-admin {
        justify-content: space-between !important;
        .el-button {
          padding: 1vh;
          .el-icon-picture-outline {
            font-size: 1.4vh !important;
          }
        }
      }
      .log-admin-list {
        background-color: rgba(0, 0, 0, 0.5);
        height: 40vh;
        .admin {
          .admin-item {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            height: 5.5vh;
            border-bottom: 1px solid #028f6d;
            margin-top: 0.5vh;
            .admin-title {
              position: absolute;
              top: 0;
              left: 0;
              font-size: 1vh;
            }
          }
        }
        ul {
          padding: 0;
          margin: 0;
          li {
            padding: 1vh 0.5vh;
            list-style: none;
            font-size: 1.3vh;
            display: flex;
            justify-content: space-around;
          }
        }
      }
    }
    .right {
      width: 13.6vw;
      height: 88vh;
      background-color: rgba(0, 0, 0, 0.5);
      .baseInfo-ctrl {
        justify-content: space-between !important;
        .el-button {
          padding: 1vh;
          .el-icon-picture-outline {
            font-size: 1.4vh !important;
          }
        }
      }
      .simple-ctrl {
        padding: 1vh 0.5vh;
        font-size: 1.3vh;
        .sub-title {
          padding-bottom: 0.5vh;
        }
        .robot-control {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 90%;
          .robot-photo {
            width: 100%;
            height: 12vh;
            box-sizing: border-box;
          }
          .robot-button {
            width: 100%;
            height: 6vh;
            box-sizing: border-box;
            display: flex;
            justify-content: space-around;
            align-items: center;
          }
        }
        .cloud-control {
          // border: 1px solid red;
          box-sizing: border-box;
          width: 100%;
          height: 22vh;
        }
      }
    }
    .center {
      width: 69vw;
      height: 86vh;
      padding-top: 2vh;
    }
  }

  .my-dialog {
    /deep/ .el-form {
      .el-form-item__error {
        bottom: -30% !important;
      }
    }
  }
}
</style>
